html {
    position: relative;
    min-height: 100%;
}
.br {
    margin-top: 0px !important;
}
.ngx-pagination .current {
    padding: 0.1875rem 0.625rem;
    background: transparent !important;
    border: 1px solid #fed221 !important;
    color: #fed221 !important;
    cursor: default;
}
.ngx-pagination a:hover, .ngx-pagination button:hover {
    color: #fed221 !important;
    border: 1px solid #fed221 !important;
    background: transparent !important;
}
.gm-style .gm-style-iw-c {
    padding: 0px !important;
}
/*.cluster div:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 40px !important;*/
/*    height: 40px !important;*/
/*    display: block !important;*/
/*    transition: all .2s ease-in-out !important;*/
/*    border-radius: 50% 50% 0 50% !important;*/
/*    box-shadow: inset 0 0 0 3px #fff 0 0 5px 0 rgba(37,37,37,0.48) !important;*/
/*    -webkit-box-shadow: inset 0 0 0 3px #fff 0 0 5px 0 rgba(37,37,37,0.48) !important;*/
/*    background: #fed221 !important;*/
/*    -webkit-transform: rotate(45deg) !important;*/
/*    -moz-transform: rotate(45deg) !important;*/
/*    -o-transform: rotate(45deg) !important;*/
/*    transform: rotate(45deg) !important;*/
/*    cursor: pointer !important;*/
/*    z-index: -1 !important;*/
/*}*/
/*.cluster div {*/
/*    font-size: 18px!important;*/
/*    width: 40px!important;*/
/*    line-height: 44px!important;*/
/*}*/
.ng-select.ng-select-single .ng-select-container {
    height: 50px !important;
    vertical-align: middle !important;
}
.ng-select .ng-select-container {
    min-height: 50px !important;
    border-radius: 5px !important;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-weight: 400 !important;
    color: #353535 !important;
    font-size: 14px !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: unset !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    font-weight: 400 !important;
    color: #353535 !important;
    font-size: 14px !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 0px !important;
    padding-left: 10px !important;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #353535 transparent transparent !important;
    border-style: solid;
    border-width: 5px 5px 2.5px;
}
